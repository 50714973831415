<template>
    <div v-if="singleData && Object.keys(singleData).length" class="advanced" :key="$route.params.kind">
        <div class="row">
            <div class="col-lg-8">
                <TextEditorCard
                    class="mb-4"
                    :title="labels[$route.params.kind]"
                    :value="textEditorValue"
                    @changed="blockTextEditorChanged({ value: $event.value, key: $route.params.kind })"
                />
                <SearchableTableCard
                    v-if="$route.params.kind == 'content'"
                    :list="modulesByOrder"
                    :header-label="labels.modules_order"
                    :sortable="true"
                />
            </div>
            <div class="col-lg-4 mb-4">
                <!-- <UploadingCard
                    :value="checkImageInstance($route.params.kind)"
                    :btn-label="labels.upload"
                    @changed="blockImageChanged({ value: $event, key: $route.params.kind })"
                    :sortable="true"
                /> -->
                <ImageInput
                    @click="showPopup = true"
                    :button-label="labels.select_image"
                    :image="checkImageInstance($route.params.kind).media"
                    :text="checkImageInstance($route.params.kind).title"
                    :link="{ name: 'media-content', params: { id: checkImageInstance($route.params.kind)._id } }"
                    :key="`image-${checkImageInstance($route.params.kind)._id}`"
                    margin="0"
                    :is-multiple="false"
                    :action="false"
                >
                    <Button
                        class="btn-slateblue"
                        :label="labels.select_image"
                        padding="11px 20px"
                        icon="custom-upload"
                        icon-position="right"
                        @click="showPopup = true"
                    />
                </ImageInput>
            </div>
        </div>
        <MediaLibrary
            v-if="showPopup"
            modal-id="mbundle-media-library"
            :type="'image'"
            custom-class="gray-header"
            v-bind:show-popup="showPopup"
            @get-media="(value) => blockImageChanged({ value: value, key: $route.params.kind })"
            @close="() => showPopup = false"
        />
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";

export default {
    name: "Advanced",
    components: {
        TextEditorCard: () => import('@/components/Input/TextEditorCard'),
        //UploadingCard: () => import('@/components/Input/UploadingCard'),
        SearchableTableCard: () => import('@/components/Table/SearchableTableCard.vue'),
        ImageInput: () => import('@/components/Media/ImageInput.vue'),
        MediaLibrary: () => import('@/components/MediaGallery/MediaLibrary.vue'),
        Button: () => import('@/components/Buttons/Button.vue')
    },
    data() {
        return {
            showPopup: false
        }
    },
    computed: {
        ...mapGetters('helper', ['labels']),
        ...mapState('monetizationBundle', ['singleData']),
        localMBData() {
            return this.$store.state.monetizationBundle.localMBData;
        },
        textEditorValue() {
            if (
                this.$route.params.kind &&
                this.localMBData[this.$route.params.kind] &&
                this.localMBData[this.$route.params.kind].text
            ) {
                return this.localMBData[this.$route.params.kind].text;
            }
            return "";
        },
        modulesByOrder() {
            let result = [];

            if (this.singleData?.modules && Array.isArray(this.singleData.modules) && this.singleData.modules.length) {
                this.singleData.modules.forEach(module => {
                    result.push({
                        _id: module._id,
                        label: module.title,
                        link: this.$router.resolve({
                            name: 'module',
                            params: {
                                id: module._id
                            }
                        }).href
                    })
                })
            }

            return result;
        }
    },
    methods: {
        ...mapMutations("monetizationBundle", {
            updateLocalMBDataObject: "updateLocalMBDataObject",
        }),
        checkImageInstance(propName) {
            if (this.localMBData[propName]?.image && Object.keys(this.localMBData[propName].image).length) return this.localMBData[propName].image;

            return {
                _id: -1,
                title: '',
                link: '',
                media: ''
            }
        },
        blockTextEditorChanged(data) {
            this.updateLocalMBDataObject({ key_0: data.key, key_1: "text", value: data.value });
        },

        blockImageChanged(data) {
            this.updateLocalMBDataObject({ key_0: data.key, key_1: "image", value: data.value });
        },
    },
};
</script>

<style lang="scss" scoped>
    ::v-deep {
        .panel {
            .extra-content {
                margin-top: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>